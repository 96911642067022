


export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return

  // Data
  const gsiLoaded = ref(false)
  const gsiInitialized = ref(false)
  const gapiInitialized = ref(false)

  // Composables
  const tokens: Ref<any> = useSessionStorage('tokens', {})
  const userJson = sessionStorage.getItem('userinfo')
  
  if (userJson == null || userJson == '{}') {
    const r = await useFetch('/api/me')
    console.log('me', r.data.value)
    if (r.data.value) {

      const user = useSessionStorage('userinfo', {})
      user.value = r.data.value
      
    } else if (to.fullPath != '/login') {
      console.log('navigate to login page')
      return navigateTo('/login')
    }
  } else {
    const { data: eventixToken } = await useFetch('/api/eventix/token')
    tokens.value.eventix = eventixToken
  
    const { data: googleToken } = await useFetch('/api/google/token')
    tokens.value.google = googleToken

    if (
      to.fullPath != '/login' && (
        !eventixToken.value?.access_token || 
        !googleToken.value?.access_token
      )
    ) {
      return navigateTo('/login')
    }
  }

  console.log('User logged in')
  return

  // Computed
  const loaded = computed(() => {
    return gsiLoaded
    return gsiInitialized.value && gapiInitialized.value
  })

  const loggedIn = computed(() => {
    return Boolean(loaded && tokens.value.eventix && tokens.value.google)
  })

  // Methods
  function onGsiLoad() {
    console.log('Gsi loaded')
    gsiLoaded.value = true

  }

  async function onGapiLoad() {
    console.log('Gapi Loaded')
    console.log('Gapi Initialised')
    gapiInitialized.value = true

    /*
    gapi.load('client', async () => {

      await gapi.client.init({
        apiKey: 'AIzaSyC9HRf0UUKFfpkZ_0gBVbE_BqOu5DwrHDs',
        discoveryDocs: [
          'https://sheets.googleapis.com/$discovery/rest?version=v4'
        ],
      })

      /*
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: '1ywjwl7_0u_ZNYhrHpuoRfiogsqnWHGg7o5Rl6WefQ8g',
        range: 'Deelnemers!A2:E',
      })
      /

      //console.log(response)
    })
    */
  }

  async function onGapiClientLoad()
  {

  }

  if (window.gsiLoaded) {
    console.log('Gsi already loaded')
    onGsiLoad()
  } else {
    window.onGsiLoad = onGsiLoad
  }

  if (to.fullPath != '/login') {
    console.log('nav to login')
    return navigateTo('/login')
  }



  if (window.gapiLoaded) {
    await onGapiLoad()
  } else {
    window.onGapiLoad = onGapiLoad
  }

  if (to.fullPath != '/login') {
    console.log('nav to login')
    return navigateTo('/login')
  }



  console.log('Waiting for loaded to be true', loaded)
  await until(loaded.value).toBe(true)
  console.log('waiting done')

  console.log(loggedIn.value, to.fullPath)
  console.log(!loggedIn.value && to.fullPath != '/login')

  
  if (!loggedIn.value && (to.fullPath != '/login')) {
    console.log('navigate to login page')
    return navigateTo('/login')
  }

  console.log('Load done')

})