import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
export default [
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  }
]