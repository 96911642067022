<script setup lang="ts">

import { dateNlNL } from 'naive-ui'

const collapsed = ref(true)

onMounted(async () => {

})

</script>

<template>
  <n-config-provider :date-locale="dateNlNL">
    <n-layout has-sider class="app-layout" position="absolute">
      <n-layout-sider
        position="absolute"
        collapse-mode="transform"
        :collapsed-width="0"
        :width="280"
        :collapsed="collapsed"
        class="app-layout-sider"
        show-trigger="bar"
        style="border-top-right-radius: 16px;"
        @collapse="collapsed = true"
        @expand="collapsed = false"
      >
        <span class="app-layout-sider__title">
        </span>

      </n-layout-sider>
    
      <n-layout
        position="absolute"
        style="min-width:1080px; transition: all 0.3s;"
        :style="{ left: collapsed ? '10px' : '280px' }"
      >
        <n-layout-header bordered>
          <div style="padding-right: 40px;">

          </div>
        </n-layout-header>
        <n-layout-content
          class="layout-content"
          style="border-radius: 0 16px 16px;"
        >
          <div style="padding: 20px 40px;">
            <NuxtPage />
          </div>
        </n-layout-content>
      </n-layout>
    </n-layout>
  </n-config-provider>

</template>
